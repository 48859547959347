<template>
  <div
    class="leaderboard"
    v-if="$store.getters['auth/getUser']['type']['id'] != 1"
  >
    <div>
      <router-link class="leaderboard__link" to="/dashboard">
        <v-icon color="#002147" small center>mdi-chevron-left</v-icon>
        {{ $t("returnToInsights") }}
      </router-link>
    </div>
    <!-- Average score evolution -->
    <v-row class="d-flex justify-center my-4"
      ><h3 class="primary--text">{{ $t("averageEvolutionScore") }}</h3></v-row
    >
    <v-row>
      <v-col cols="12">
        <v-card
          class="d-flex align-center pa-12"
          color="white"
          style="border-radius: 30px"
          elevation="0"
        >
          <v-col cols="12">
            <line-chart
              v-if="lineChartData != null"
              :series="lineChartData"
              :cats="cats"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- !Average score evolution -->
    <!-- Leaderboard of the group -->
    <v-row>
      <v-col cols="12">
        <v-card  color="white"
          style="border-radius: 30px"
          elevation="0">
          <v-card-title class="justify-center">
            <h5>{{ $t("leaderboardOfGroup") }}</h5>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
               :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="stores"
            :items-per-page="5"
            :sort-by="['score']"
            :sort-desc="[true]"
            :search="search"
            class="elevation-0"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- !Leaderboard of the group -->
  </div>
  <div v-else>
    <v-img alt="Logo" src="@/assets/logo-dark.png" contain aspect-ratio="4">
    </v-img>
  </div>
</template>
<style scoped lang="scss">
.v-sheet.v-card.v-sheet--shaped {
  border-radius: 20px 20px;
}

.v-btn {
  text-transform: unset !important;
}

.leaderboard {
  &__link {
    text-decoration: none;
    display: block;
    margin-bottom: 4rem;
  }
}
</style>
<script>
import LineChart from "@/components/charts/Line.vue";
export default {
  name: "Dashboard",

  components: {
    LineChart,
  },

  mounted: function () {
    this.getDataCharts();
    this.getStoresRanks();
  },

  data() {
    return {
      search: "",
      lineChartData: null,
      headers: [
        {
          text: this.$t("rank"),
          value: "rank",
        },
        { text: this.$t("score"), value: "score" },
        { text: this.$t("store"), value: "store" },
        { text: this.$t("location"), value: "location" },
      ],
      stores: [],
      cats: null,
    };
  },

  methods: {
    /**
     * Set data to the charts.
     * @public
     */
    getDataCharts() {
      this.lineChartData =[ {
        name: this.$t("score"),
        data: [0, 0.5, 2, 3.5, 5],
      }];
      this.cats = ["Jan22", "Feb22", "Mar22", "Apr22", "May22", "Jun22"];
    },

    /**
     * Set the stores ranks.
     * @public
     */
    getStoresRanks() {
      /*
      var user = this.$store.getters["auth/getUser"]; 
      let url = this.$config.api.route.companiesStores;
      url = url.replace(":id", user.company_id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.stores = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
      
      */
      this.stores = [
        {
          score: 4.1,
          store: "Feira Nova",
          location: "Porto, Portugal",
        },
        {
          score: 4.7,
          store: "A.S. Boavista",
          location: "Porto, Portugal",
        },
        {
          score: 4.7,
          store: "Dison",
          location: "Santarém, Portugal",
        },
        {
          score: 4.2,
          store: "Penedono",
          location: "Viseu, Portugal",
        },
        {
          score: 4.1,
          store: "Mora",
          location: "Évora, Portugal",
        },
      ];
      // change sort function
      this.stores.sort(function (a, b) {
        return b.score - a.score;
      });

      var rank = 1;
      for (var i = 0; i < this.stores.length; i++) {
        // increase rank only if current score less than previous
        if (i > 0 && this.stores[i].score < this.stores[i - 1].score) {
          rank++;
        }
        this.stores[i].rank = "#" + rank;
      }
    },
  },
};
</script>
